import React, { useContext, useEffect, useRef } from 'react'
import './style.scss'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../context/appContext'
import Dropdown from '../Dropdown';
const Icons = React.lazy(() => import('../Icons'));

const Header = () => {
  const navigate = useNavigate();
  const header = ['Merchants','Offers']
  const oheader = ['Manage Offers','Add Offers']
  const [drop, setDrop] = useState(false)
  const { state, dispatch } = useContext(AppContext);
  const dropdownRef = useRef(null);
  const [dropList, setDropList] = useState(["Logout"])
  const [dropVal, setDropVal] = useState("")

  useEffect(() => {
    if(localStorage.getItem("role")==="Admin") {
      setDropList(["Manage User",...dropList])
    }
    const path = window.location.pathname.split('/');
    if (path[1]==='offers') {
      if (path[2]==='manage-offers') {
        dispatch({ type: "SET_SELECTED_HEADER", payload: 'Offers' });
        dispatch({ type: "SET_SELECTED_SEC_HEADER", payload: 'Manage Offers' });
      }
      else if (path[2]==='add-offers') {
        dispatch({ type: "SET_SELECTED_HEADER", payload: 'Offers' });
        dispatch({ type: "SET_SELECTED_SEC_HEADER", payload: 'Add Offers' });
        dispatch({ type: "SET_UPLOADTAB", payload: 'Single Upload' });
      }
      else {
        dispatch({ type: "SET_SELECTED_HEADER", payload: 'Offers' });
        dispatch({ type: "SET_SELECTED_SEC_HEADER", payload: 'Add Offers' });
        dispatch({ type: "SET_UPLOADTAB", payload: 'Bulk Upload' });
      }
    }
    else if (path[1]==='merchants') {
      // if (path[2]==='merchant-hub') {
        dispatch({ type: "SET_SELECTED_HEADER", payload: 'Merchants' });
      // }
      // else {
      //   dispatch({ type: "SET_SELECTED_HEADER", payload: 'Dashboard' });
      //   dispatch({ type: "SET_SELECTED_SEC_HEADER", payload: 'Insights' });
      // }
    }
    else {
      dispatch({ type: "SET_SELECTED_HEADER", payload: '' });
      dispatch({ type: "SET_SELECTED_SEC_HEADER", payload: '' });
    }
    if (path[1]==='user-management') {
      setDropVal('Manage User')
    }
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setDrop(false); // Close the dropdown
      }
  };
  document.addEventListener('mousedown', handleClickOutside);

    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
  },[])

  const handleHeader = (item) => {
    dispatch({ type: "SET_SELECTED_HEADER", payload: item });
    if (item==='Offers') {
      dispatch({ type: "SET_SELECTED_SEC_HEADER", payload: oheader[0] });
      navigate('/offers/manage-offers')
    }
    else {
      // dispatch({ type: "SET_SELECTED_SEC_HEADER", payload: dheader[0] });
      navigate('/merchants/merchants-hub')
    }
  }
  const handleSecHeader = (item) => {
    dispatch({ type: "SET_SELECTED_SEC_HEADER", payload: item });
    if (state.selectedHeader==='Offers') {
      if (item==='Manage Offers') {
        navigate('/offers/manage-offers')
      }
      else {
        navigate('/offers/add-offers')
        dispatch({ type: "SET_UPLOADTAB", payload: 'Single Upload' });
      }
    }
    // else {
    //   if (item==='Reports') {
    //     navigate('/dashboard/reports')
    //   }
    //   else {
    //     navigate('/dashboard/insights')
    //   }
    // }
  }

  const dropdown = () => {
    setDrop(!drop)
  }
  const dropFunc = (val) => {
    setDropVal(val)
    if (val==="Manage User") {
      dispatch({ type: "SET_SELECTED_HEADER", payload: '' });
      dispatch({ type: "SET_SELECTED_SEC_HEADER", payload: '' });
      navigate('/user-management')
      
    }
    if (val==="Logout") {
      localStorage.removeItem("token")
      localStorage.removeItem("role")
      navigate('/login')
    }
  }

  const secHeader = state.selectedHeader==='Offers' && oheader;
  return (
    <>
    <div className='header-div'>
        <div>
          <Icons     
              name= "maya-logo"
              width= "143"
              height= "32"
              fill= "#000"
            />
        </div>
        <div className='flex header'>
          {header.map((item)=> {
            return <div className={`${state.selectedHeader===item && 'borderB'} h-title`} onClick={()=>handleHeader(item)}>{item}</div>
          })}
        </div>
        <div className='flex user'>
          <div><Icons     
              name= "bell"
              width= "32"
              height= "32"
              fill= "#000"
            />
          </div>
          <div className='avatar' ref={dropdownRef} >
          <Icons     
              name= "avatar"
              width= "36"
              height= "36"
              fill= "#000"
            />
          <span className='am' onClick={dropdown}>
            <Icons     
              name= "arrow-down"
              width= "18"
              height= "18"
              fill= "#424d5f"
            />
          </span>
          {drop && <Dropdown dropList={dropList} dropFunc={dropFunc} dropVal={dropVal} dropClass={"drop1"}/>}
          </div>

        </div>
    </div>
    {state.selectedHeader==='Offers' &&
    <div className='secheader-div'>
        <div className='flex secheader'>
          {secHeader.map((item)=> {
            return <div className={`${state.selectedSecHeader===item && 'texth'} s-title`} onClick={()=>handleSecHeader(item)}>{item}</div>
          })}
        </div>
    </div>
    }
    </>
  )
}

export default Header