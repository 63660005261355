import React, { useRef, useState, useEffect, useContext } from 'react';
import './style.scss'
import Icons from '../Icons';
import { Tooltip } from 'react-tooltip';
import { AppContext } from '../../context/appContext'
import { useNavigate } from 'react-router-dom';
import { getApiCall, postApiCall, postApiCallNew, putApiCall, putApiCallNew } from '../../service';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoaderNew from '../LoaderNew';

const OffersHubTable = (props) => {
  const { offset, total, limit, setOffset, setSort, setSortOrder, sort, sortOrder,offerListApi, merchantSearch, setMerchantSearch, searched, deleteSearch  } = props;
  const navigate = useNavigate();
  const [currentPage,setCurrentPage] = useState(1);
  const [lastList, setLastList] = useState({});
  const [activeRow, setActiveRow] = useState(null);
  const { state, dispatch } = useContext(AppContext);
  const [totalSelected, setTotalSelected] = useState([])
  const [loading,setLoading] = useState(false)
  const wrapperRef = useRef(null)
  let tableHeader = props?.showFilters ?
    [{label:'Offer ID', value:"offerId"}, 
    {label:'Offer Title', value:"offerTitle"}, 
    {label:'Status', value:"offerStatus"}, 
    {label:'Offer Type', value:"offerType"}, 
    {label:'Merchant', value:"merchantName"},
    {label:'Offer Source', value:"offerSource"},
    {label:'Start Date', value:"validFrom"}, 
    {label:'End Date', value:"validTo"}, 
    {label:'Channels', value:"channel"}, 
    {label:'Action',value:"action"}] :
    [{label:'Offer ID', value:"offerId"}, 
    {label:'Offer Title', value:"offerTitle"}, 
    {label:'Status', value:"offerStatus"}, 
    {label:'Offer Type', value:"offerType"}, 
    {label:'Start Date', value:"validFrom"}, 
    {label:'End Date', value:"validTo"}, 
    {label:'Channels', value:"channel"}]

    // State to store selected options for each page

    const [selectedOptions, setSelectedOptions] = useState([])

    useEffect(()=> {
      localStorage.removeItem("wonderList")
    },[])

    useEffect(() => {
      const storedSelectedOptions = JSON.parse(localStorage.getItem(`wonderList`));
      setSelectedOptions(storedSelectedOptions && storedSelectedOptions[parseInt((offset+limit)/limit)]? storedSelectedOptions[parseInt((offset+limit)/limit)] : [])
      const a = storedSelectedOptions ? Object.values(storedSelectedOptions).flat():[];
      setTotalSelected(a)
    },[offset,limit,localStorage.getItem("wonderList")])

    const handleCheckboxChange = (option) => {
      const currentIndex = selectedOptions?.indexOf(option);
      const newSelectedOptions = [...selectedOptions];
  
      if (currentIndex === -1) {
        newSelectedOptions.push(option);
      } else {
        newSelectedOptions.splice(currentIndex, 1);
      }

      const localList = JSON.parse(localStorage.getItem("wonderList"))
      let a=localList? localList:{}
      a[parseInt((offset+limit)/limit)] = newSelectedOptions;
      localStorage.setItem('wonderList',JSON.stringify(a))
  
      setSelectedOptions(newSelectedOptions);
    };
  
    const handleSelectAllChange = (event) => {
      if (event.target.checked) {
        const allOptions = props?.data?.map(option => option.offerId);
        const localList = JSON.parse(localStorage.getItem("wonderList"))
        let a=localList? localList:{}
        a[parseInt((offset+limit)/10)] = allOptions;
        localStorage.setItem('wonderList',JSON.stringify(a))
        setSelectedOptions(allOptions);
      } else {
        const localList = JSON.parse(localStorage.getItem("wonderList"))
        let a=localList? localList:{}
        a[parseInt((offset+limit)/10)] = [];
        localStorage.setItem('wonderList',JSON.stringify(a))
        setSelectedOptions([]);
      }
    };

    const handleNextPage = () => {
      if (offset + limit < total) {

        // const localList = JSON.parse(localStorage.getItem("wonderList"))
        // let a=localList? localList:{}
        // a[parseInt((offset+limit)/20)] = selectedOptions;
        // localStorage.setItem('wonderList',JSON.stringify(a))
        setOffset((offset + limit))
        
        setSelectedOptions([])
      }
    };
  
    const handlePreviousPage = () => {
      if (offset > 0) {
        // const localList = JSON.parse(localStorage.getItem("wonderList"))
        // let a=localList? localList:{}
        // a[parseInt((offset+limit)/20)] = selectedOptions;
        // localStorage.setItem('wonderList',JSON.stringify(a))
        setOffset(offset - limit)
        
        setSelectedOptions([])
      }
    };

    


  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setActiveRow(null)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const editOffer = (id) => {
    navigate('/offers/add-offers',{state: { id, searched }})
    dispatch({ type: "SET_SELECTED_SEC_HEADER", payload: 'Add Offers' });
    dispatch({ type: "SET_UPLOADTAB", payload: 'Single Upload' });
  }

  const approval = (dec) => {
    // const localList = JSON.parse(localStorage.getItem("wonderList"))
    // let a=localList? localList:{}
    // a[parseInt((offset+limit)/20)] = selectedOptions;

    // localStorage.setItem('wonderList',JSON.stringify(a))

    try {
      let params ={
        "id": Object.values(totalSelected).flat(),
        "action":dec,
        "email":localStorage.getItem("email")
      }
      setLoading(true)
      putApiCallNew("/offers/approval",params).then((res)=> {
        
        if (res.status===200) {
          setLoading(false)
          toast.success(res.status)
          localStorage.removeItem("wonderList")
          offerListApi();
        }
        else if (res?.status===500){
          setLoading(false)
          toast.error("Internal Server Error")
        }
        else if (res?.status===401){
          setLoading(false)
          localStorage.removeItem("token")
          navigate('/login')
          setTimeout(() => {
            toast.error("Session has expired. Please login again.")
          }, 1000);
        }
        else {
          setLoading(false)
          toast.error(JSON.stringify(res?.error?.message))
          toast.error(JSON.stringify(res?.detail))
        }
      })
    }
    catch(e) {
      console.log(e)
    }
    }
    
  const offerDec = (dec) => {
  
      try {
        if(totalSelected.length!==0) {
          let params ={
            "offerIds": Object.values(totalSelected).flat(),
            "actionType":dec==="pause" && "OFFER_PAUSE",
          }
          setLoading(true)
          console.log(params)
          postApiCallNew("/offers/status",params).then((res)=> {
            
            if (res.status===200) {
              setLoading(false)
              toast.success("Offer pause has been initiated. The offer will be paused in 15 mins")
              localStorage.removeItem("wonderList")
              offerListApi();
            }
            else if (res?.status===500){
              setLoading(false)
              toast.error("Internal Server Error")
            }
            else if (res?.status===401){
              setLoading(false)
              localStorage.removeItem("token")
              navigate('/login')
              setTimeout(() => {
                toast.error("Session has expired. Please login again.")
              }, 1000);
            }
            else {
              setLoading(false)
              toast.error(JSON.stringify(res?.error?.message))
              toast.error(JSON.stringify(res?.detail))
            }
          })
        }
      }
      catch(e) {
        console.log(e)
      }
    }
    const singleOfferDec = (id,dec) => {
  
      try {
        let params ={
          "offerIds": [id],
          "actionType":dec==="Pause"? "OFFER_PAUSE":dec==="Resume"? "OFFER_RESUME":"OFFER_PUBLISH",
        }
        setLoading(true)
        postApiCallNew("/offers/status",params).then((res)=> {
          if (res.status===200) {
            setLoading(false)
            toast.success(dec==="Pause"? "Offer pause has been initiated. The offer will be paused in 15 mins":dec==="Resume"? "Offer resume has been requested. The status of the offer will change in 15 mins.":"The offer publish is in progress. The status of the offer will change in 15 mins.")
            localStorage.removeItem("wonderList")
            offerListApi();
          }
          else if (res?.status===500){
            setLoading(false)
            toast.error("Internal Server Error")
          }
          else if (res?.status===401){
            setLoading(false)
            localStorage.removeItem("token")
            navigate('/login')
            setTimeout(() => {
              toast.error("Session has expired. Please login again.")
            }, 1000);
          }
          else {
            setLoading(false)
            toast.error(JSON.stringify(res?.error?.message))
            toast.error(JSON.stringify(res?.detail))
          }

        })
      }
      catch(e) {
        console.log(e)
      }
    }

  return (
    <>
    {
      loading && <LoaderNew/>
    }
      {/* {
        props?.showFilters &&
        <div className='filter-div'>
          <div>
            <Icons
              name="search"
              width="24"
              height="24"
              fill="rgba(107, 114, 128, 1)"
            />
            <span>Search</span>
          </div>
          <div>
            <Icons
              name="sort"
              width="24"
              height="24"
              fill="rgba(107, 114, 128, 1)"
            />
            <span>Sort</span>
          </div>
          <div>
            <Icons
              name="filter"
              width="24"
              height="24"
              fill="rgba(107, 114, 128, 1)"
            />
            <span>Filters</span>
          </div>
          <div className='pagination'>
            1 - 100 of 4,142
            <Icons
              name="left-arrow"
              width="7"
              height="13"
              fill="rgba(0, 0, 0, 1)"
            />
            <Icons
              name="right-arrow"
              width="7"
              height="13"
              fill="rgba(0, 0, 0, 1)"
            />
          </div>
        </div>
      } */}
      {
        props?.showFilters &&
      <div className='filter-div-offer' style={{ marginTop: 30 }} >
        <div className='filter-search'>
        {merchantSearch && 
        <><div className='search-heading'>
          <span onClick={deleteSearch} style={{paddingTop:"3px"}}>
          <Icons
              name="nav-arrow-left"
              width="32"
              height="32"
              fill="rgba(0, 0, 0, 1)"
          />
          </span>
          Showing Results for <span className='search-name'>"{searched?.label}"</span>
        </div>
        {/* <div className='sep'>|</div> */}
        </>
        }

        <div className='flex'>
          {/* <div className='oselect'>{totalSelected.length} Offers Selected<span>out of {total}</span></div> */}
          {/* <div className='sep'>|</div> */}
          <div className='arp'>
            {/* <div onClick={()=>approval("approve")}>Approve</div>
            <div onClick={()=>approval("reject")}>Reject</div> */}
            {/* <div style={{cursor: totalSelected.length===0? "not-allowed":"pointer"}} onClick={()=>offerDec("pause")}>Pause</div> */}
          </div>
        </div>
        </div>
        {props.data?.length!==0 && <div className='pagination'>
          {offset + 1} - {offset + limit > total ? total : offset + limit} of {total}
          <span onClick={() => { handlePreviousPage()}} >
            <Icons
              name="left-arrow"
              width="7"
              height="13"
              fill={offset === 0 ? "rgba(0, 0, 0, 0.3)" : "rgba(0, 0, 0, 1)"}
            /></span>
          <span onClick={() => { handleNextPage()}} >
            <Icons
              name="right-arrow"
              width="7"
              height="13"
              fill={offset + limit >= total ? "rgba(0, 0, 0, 0.3)" : "rgba(0, 0, 0, 1)"}
            />
          </span>
        </div>}
      </div>
      }
      <div className='offers-hub-table'>
        {props.data?.length===0 ? 
            <div className='no-result'><h2>No Results</h2></div>
            :
        <table>
        <thead>
            {/* <td 
            >
              { props?.showFilters &&
              <label className="container">
                <input
                  type="checkbox"
                  onChange={handleSelectAllChange}
                  checked={selectedOptions?.length === props.data?.length && props.data?.length !== 0}
                />
                <span className="checkmark1"></span>
              </label>
              }
            </td> */}
            {tableHeader && tableHeader.map(
              ({ label, value }) => {
                return (<td>
                  <div
                    style={{cursor:value==="action"&&"default"}}
                    onClick={
                      () => {
                        if(props?.showFilters && value!=="action")
                          {
                        if (sortOrder === "asc" && sort === value) {
                          setSortOrder("desc")
                        }
                        else if (sortOrder === "desc" && sort === value) {
                          setSortOrder("")
                          setSort("")
                        }
                        else {
                          setSortOrder("asc")
                          setSort(value)
                        }
                      }
                    }
                    }
                  >
                    {label}
                    {
                      value !== 'action' && props?.showFilters &&
                      <span className='sort' >
                        <div><Icons name="arrow-up" width={6} height={8} fill={props?.sort === value && props.sortOrder === "asc" ? "#1A5DEE" : "#9CA3AF"} /></div>
                        <div><Icons name="arrow-down" width={6} height={8} fill={props?.sort === value && props.sortOrder === "desc" ? "#1A5DEE" : "#9CA3AF"} /></div>
                      </span>
                    }
                  </div>
                </td>)
              }
            )}
          </thead>
          <tbody>
            {props.data?.map((offerDetails) => {
              return (<tr>
                {/* <td>
                  { props?.showFilters &&
                <label className="container">
                  <input
                    type="checkbox"
                    onChange={() => handleCheckboxChange(offerDetails.offerId)}
                    checked={selectedOptions?.includes(offerDetails.offerId)}
                  />
                  <span className="checkmark1"></span>
                </label>
                }
                  
                </td> */}
                <td >{offerDetails.offerId}</td>
                <td className='offer-table-desc'>
                  {offerDetails?.offerTitle ? offerDetails?.offerTitle:"-" }
                  {/* <div
                  data-tooltip-id="offer-description"
                  data-tooltip-content={`${props?.showFilters? offerDetails?.offerDescription:offerDetails?.offerDetails}`}
                  >
                  {props?.showFilters? 
                  (offerDetails.offerDescription?.length > 40 ? offerDetails.offerDescription?.substring(0, 40) + '...' : offerDetails.offerDescription)
                  :(offerDetails.offerDetails?.length > 40 ? offerDetails.offerDetails?.substring(0, 40) + '...' : offerDetails.offerDetails)}</div> */}
                  {/* <div 
                  data-tooltip-id="offer-description"
                  data-tooltip-html={`${props?.showFilters? offerDetails?.offerDescription:offerDetails?.offerDetails}`}
                  dangerouslySetInnerHTML={{ __html: props?.showFilters? 
                  (offerDetails.offerDescription?.length > 40 ? offerDetails.offerDescription?.substring(0, 40) + '...' : offerDetails.offerDescription)
                  :(offerDetails.offerDetails?.length > 40 ? offerDetails.offerDetails?.substring(0, 40) + '...' : offerDetails.offerDetails) }} /> */}
                  {/* <Tooltip 
                  id="offer-description"
                  style={{maxWidth:"300px"}} /> */}
                  </td>
                <td><button className={offerDetails.offerStatus?.toLowerCase()==="ready to publish"? "readytopublish":offerDetails.offerStatus?.toLowerCase()}>{offerDetails.offerStatus}</button></td>
                <td>{offerDetails.offerType?.charAt(0).toUpperCase() + offerDetails.offerType?.slice(1).toLowerCase()}</td>
                {props?.showFilters &&
                  <>
                    <td className='offer-table-merchant'>{offerDetails.merchantName}</td>
                    <td>{offerDetails.offerSource}</td>
                  </>
                }
                <td>{offerDetails.validFrom?.split(' ')[0]}</td>
                <td>{offerDetails.validTo?.split(' ')[0]}</td>
                <td>{offerDetails.channel?.charAt(0).toUpperCase() + offerDetails.channel?.slice(1).toLowerCase()}</td>
                {!props?.showFilters ? <></> :
                  offerDetails.action ? 
                  <td >
                    <div className='action-div'>
                    <div className='action'>Approve</div>
                    <div className='seperator'>|</div>
                    <div className='action'>Reject</div></div> </td> :
                    <td >
                    {offerDetails.offerStatus==="Pending" ?
                    <div className='action-div'>-</div>:
                    <div className='action-div'>
                    {offerDetails.offerStatus!=="Live" && <div className='action edit' onClick={()=>{editOffer(offerDetails.offerId)}}>Edit</div>}
                    {offerDetails.offerStatus!=="Live" && <div className='seperator'>|</div>}
                    <div onClick={()=>singleOfferDec(offerDetails.offerId,offerDetails.offerStatus==="Paused"? "Resume":offerDetails.offerStatus==="Ready to Publish"?"Publish":"Pause")} 
                    className={`action ${offerDetails.offerStatus==="Paused"? "resume":offerDetails.offerStatus==="Ready to Publish"? "publish":"pause"}`} 
                    >
                      {offerDetails.offerStatus==="Paused"? "Resume":offerDetails.offerStatus==="Ready to Publish"?"Publish":"Pause"}
                      </div>
                    </div>} 
                    </td>
                    // <td className='cursor'>
                    //   <div className='more-actions' onClick={(e) => { e.stopPropagation(); setActiveRow(offerDetails.id) }}>...</div>
                    //   {activeRow === offerDetails.id && <div className='more-actions-menu' ref={wrapperRef}>
                    //     <div onClick={() => { setActiveRow(null) }}>Edit</div>
                    //     <div onClick={() => { setActiveRow(null) }}>Preview</div>
                    //     <div onClick={() => { setActiveRow(null) }}>Delete</div>
                    //   </div>}
                    // </td>
                    }
              </tr>)
            })}
          </tbody>
        </table>
        }
      </div>
      <ToastContainer
      // className="toaster-top toast-error-container"
      autoClose={6000}
      position='top-right'
    />
    </>
  )
}

export default OffersHubTable