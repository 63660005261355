
import React, {Suspense, useEffect} from 'react';
import { BrowserRouter, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import LoaderNew from './components/LoaderNew';
import Header from './components/Header';
import UserManagment from './Pages/UserManagement';
const Login = React.lazy(() => import('./components/Login'));
const Reset = React.lazy(() => import('./components/Reset'));
const Register = React.lazy(() => import('./components/Register'));
const ManageOffers = React.lazy(() => import('./Pages/ManageOffers'));
const AddOffers = React.lazy(() => import('./Pages/AddOffers'));
const BulkUpload = React.lazy(() => import('./Pages/BulkUpload'));
const Layout = React.lazy(() => import('./Layout'));
const MerchantsHub = React.lazy(() => import('./Pages/MerchantsHub'));
const AddMerchants = React.lazy(() => import('./Pages/AddMerchants'));
const LinkExpire = React.lazy(() => import('./components/LinkExpire'));

const SuspenseLayout = () => (
  <Suspense fallback={<LoaderNew/>}>
    <Outlet/>
  </Suspense>
);

const UserManagementComp = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    }
  }, [navigate]);

  
  return (
    <>
    {localStorage.getItem("token") &&
    <>
        <Header />
        <UserManagment/>
    </>
    }
    </>
  )
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route element={<SuspenseLayout />}>
        <Route path='/login' element={<Login/>}/>
        <Route path='/link-expire' element={<LinkExpire/>}/>
        <Route path='/forgotPassword' element={<Reset/>}/>
        <Route path='/reset-password' element={<Reset token={true} />}/>
        <Route path='/register' element={<Register/>}/>
        <Route path='/offers' element={<Layout/>}>
          <Route path='manage-offers' element={<ManageOffers/>}/>
          <Route path='add-offers' element={<AddOffers/>}/>
          <Route path='bulk-upload' element={<BulkUpload/>}/>
        </Route>
        <Route path='/merchants' element={<Layout/>}>
          <Route path='merchants-hub' element={<MerchantsHub/>}/>
          <Route path='add-merchant' element={<AddMerchants/>}/>
        </Route>
        <Route path='/user-management' element={<UserManagementComp/>} />
        <Route path='*' element={<Login/>}/>
      </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
