import { useReducer } from 'react';
import { createContext } from 'react';

export const AppContext = createContext("");

const appReducer = (state,action) =>{
    switch (action.type){
        case 'GET_SELECTED_HEADER':
            return state.selectedHeader
        case 'SET_SELECTED_HEADER':
            return {...state,selectedHeader:action.payload}
        case 'GET_SELECTED_SEC_HEADER':
            return state.selectedSecHeader
        case 'SET_SELECTED_SEC_HEADER':
            return {...state,selectedSecHeader:action.payload}
        case 'GET_UPLOADTAB':
            return state.uploadTab
        case 'SET_UPLOADTAB':
            return {...state,uploadTab:action.payload}
        case 'GET_ROLE':
            return state.role
        case 'SET_ROLE':
            return {...state,role:action.payload}
        default:
            return state
    }
}

export const AppContextProvider = ({children}) =>{

    const [state,dispatch] = useReducer(appReducer,{
        selectedHeader:"Offers",
        selectedSecHeader: "Manage Offers",
        uploadTab: "Single Upload",
        role: ""
    })

    return(
        <AppContext.Provider value={{state,dispatch}}>
            {children}
        </AppContext.Provider>
    )
}