import React, { useContext, useEffect, useState } from 'react'
import "./style.scss"
const Icons = React.lazy(() => import('../../components/Icons'));

const PopupSec = ({popupMessage, ipopup, setIpopup}) => {

    useEffect(()=> {
        if (ipopup) {
            setTimeout(() => {
                setIpopup(false)
            }, 5000);
        }
    },[ipopup])

    return (
        <div className={`popup-sec ${ipopup && "popup-visible"}`}>
          <div className='pop-sec-content'>
            <div>
            {
                popupMessage?.map((item)=> {
                    return <div>{item}</div>
                })
            }
            </div>
            <div style={{cursor:"pointer",display:"flex",alignItems:"center"}} onClick={()=> setIpopup(false)}>
                <Icons
                  name="xcancel"
                  width="20"
                  height="20"
                  fill="#E5E7EB"
                /> 
            </div>
          </div>
          
        </div>
      );
}

export default PopupSec