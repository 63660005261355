import React from 'react'
import "./style.scss"
const Icons = React.lazy(() => import('../Icons'));

const Dropdown = (props) => {

  return (
    <div className={`drop-div ${props?.dropClass==="drop1"? "drop1":
      props?.dropClass==="drop2"? "drop2":props?.dropClass==="drop3"? "drop3":
      props?.dropClass==="drop4"? "drop4":props?.dropClass==="drop5"? "drop5":"drop6"
    } ${props?.dropClass==="drop4" && (props?.drop && props?.dropC===props?.userDetails?.email) && "drop-visible"}
    ${props?.dropClass==="drop6" && (props?.drop) && "drop-visible"}`}>
    {props?.dropList?.map((ele)=> {
        return <div className={`dropdown ${props?.dropVal===ele && "dropAct"} ${(props?.dropClass==="drop4"||props?.dropClass==="drop6") && "dropdown46"}`} onClick={()=>props?.dropFunc(ele)}>
          {(props?.dropClass==="drop4"||props?.dropClass==="drop6") && 
          <span className='check'>
            <Icons     
                name= "check"
                width= {props?.dropClass==="drop6"?"1.042vw":"20"}
                height= {props?.dropClass==="drop6"?"1.852vh":"20"}
                fill= "#fff"
              />
          </span>}
          {ele}</div>
    })
    }
    </div>
  )
}

export default Dropdown