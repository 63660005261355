import React from 'react'
import load from "../../assets/img/loader.gif";
import './style.scss'

const LoaderNew = () => {
  return (
    <div className='loader'>
      <img src={load} width={400} height={300} alt='loader'/>
    </div>
    
  )
}

export default LoaderNew