import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import userImage from '../../assets/img/user-img.png'
import Dropdown from '../Dropdown';
import AsyncSelect from 'react-select/async';
const Icons = React.lazy(() => import('../../components/Icons'));

function Sidebar({ toggleSidebar,isOpen, userDetail, countries, roles, editUser }) {
  // const [userDetails, setUserDetails] = useState({
  //   profilePicture: 'path/to/profile-pic.jpg',
  //   email: 'marcus.franci@gmail.com',
  //   name: 'Marcus Franci',
  //   designation: 'Product Manager',
  //   country: 'India',
  //   permission: 'Manager',
  //   isActive: true,
  // });
  const [userId, setUserId] = useState("")
  const [designation, setDesignation] = useState(userDetail?.designation)
  const [designationf, setDesignationf] = useState(userDetail?.designation)
  const [sCheck, setSCheck] = useState(userDetail?.status)
  const [sCheckf, setSCheckf] = useState(userDetail?.status)
  const [save, setSave] = useState(false)
  const permRef = useRef(null);
  const permList = (roles&&roles)
  const [permDrop, setPermDrop] = useState(false)
  const [permDropVal, setPermDropVal] = useState(userDetail?.userPermission)
  const [permDropValf, setPermDropValf] = useState(userDetail?.userPermission)

  const perDropdown = () => {
    setPermDrop(!permDrop)
  }
  const permdropFunc = (val) => {
    setPermDropVal(val)
    setPermDrop(false)
  }

  const counRef = useRef(null);
  const counList = (countries&&countries)
  const [counDrop, setCounDrop] = useState(false)
  const [counDropVal, setCounDropVal] = useState(userDetail?.country)
  const [counDropValf, setCounDropValf] = useState(userDetail?.country)

  const counDropdown = () => {
    setCounDrop(!counDrop)
  }
  const coundropFunc = (val) => {
    setCounDropVal(val)
    setCounDrop(false)
  }
  const handleCountry = (country) => {
    setCounDropVal(country)
  }

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setUserDetails(prevDetails => ({
  //     ...prevDetails,
  //     [name]: value,
  //   }));
  // };

  // const handleToggle = () => {
  //   setUserDetails(prevDetails => ({
  //     ...prevDetails,
  //     isActive: !prevDetails.isActive,
  //   }));
  // };
  useEffect(()=> {
    setUserId(userDetail?.id)
    setDesignation(userDetail?.designation)
    setDesignationf(userDetail?.designation)
    setSCheck(userDetail?.status==="ACTIVE"? true:false)
    setSCheckf(userDetail?.status==="ACTIVE"? true:false)
    setPermDropVal(userDetail?.userPermission)
    setPermDropValf(userDetail?.userPermission)
    setCounDropVal({label:userDetail?.country,value:userDetail?.country})
    setCounDropValf({label:userDetail?.country,value:userDetail?.country})
  },[userDetail])

  useEffect(()=> {
    if ((designation!==designationf && designation) || sCheck!==sCheckf || permDropVal!==permDropValf || counDropVal?.label!==counDropValf?.label) {
      console.log("save")
      setSave(true)
    }
    else {
      setSave(false)
    }
  },[designation,sCheck,permDropVal,counDropVal])

  useEffect(()=> {
    const handleClickOutside = (event) => {
        if (permRef.current && !permRef.current.contains(event.target)) {
            setPermDrop(false); // Close the dropdown
        }
        if (counRef.current && !counRef.current.contains(event.target)) {
            setCounDrop(false); // Close the dropdown
        }
    };
    document.addEventListener('mousedown', handleClickOutside);
  
      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
  },[])

  const toggleSwitch = () => {
    setSCheck(!sCheck)
  }

  const handleDesignationChange = (des) => {
    setDesignation(des)
  }

  const handleSave = () => {
    // Save user details logic here
    let params = {
      "userId": userId,
      "country": counDropVal?.label,
      "designation": designation,
      "roleName": permDropVal,
      "userStatus": sCheck? "ACTIVE":"INACTIVE"
    }
    toggleSidebar();
    editUser(params);

  };

  return (
    <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
      <button className="close-button" onClick={toggleSidebar}>
      <Icons     
        name= "cancel"
        width= "1.667vw"
        height= "2.963vh"
        fill= ""
    />
      </button>
      <div className="sidebar-content">
        <div className='user-det'>User Details</div>
        <div className='last'>{userDetail?.lastActive}</div>
        <div className='profile mt-27'>Profile Picture</div>
        <div className='mt-16'><img src={userImage} alt="Profile" className="sidebar-profile-pic" /></div>
        <div className="user-details">
            <div className='det-text'>Name</div>
            <div className={`offer-inputbox input-disable`}>
            <input
            type="text"
            className='offer-input'
            placeholder="Select Offer Type"
            value={userDetail?.userName}
            disabled
            />
            </div>

            <div className='det-text'>Email ID</div>
            <div className={`offer-inputbox input-disable`}>
            <input
            type="text"
            className='offer-input'
            placeholder="Select Offer Type"
            value={userDetail?.email}
            disabled
            />
            </div>

            <div className='det-text'>User Designation</div>
            <div className={`offer-inputbox`}>
            <input
            type="text"
            className='offer-input'
            placeholder="Select User Designation"
            value={designation}
            onChange={(e) => handleDesignationChange(e.target.value)}
            // disabled
            />
            </div>

            <div className='det-text'>Country</div>
            <div className={``} ref={counRef}>
              <div className='country-div' onClick={counDropdown}>
                <AsyncSelect
                    placeholder="Select your Country"
                    value={counDropVal}
                    onChange={handleCountry}
                    defaultOptions={countries}
                    // onFocus={()=>handleCountryFocus()}
                    loadOptions={(inputValue, callback) => {
                      callback(countries.filter((i) =>
                        i.label.toLowerCase().includes(inputValue.toLowerCase())
                      ))
                    }
                    }
                    menuIsOpen={counDrop}
                />
                <div className={`droparrr country-drop ${counDrop && "arr-rot"}`}>
                <Icons     
                name= "nav-arrow-down"
                width= "1.25vw"
                height= "2.222vh"
                fill= "#374151"
              />
                </div>
              </div>
              
            </div>
            
            {/* <div className={`offer-inputbox ${counDrop && "visited"}`} ref={counRef}>
              <div className='permdrop' onClick={counDropdown}>
                <div style={{color:"#111827"}}>{counDropVal}</div>
                <div className={`droparrr ${counDrop && "arr-rot"}`}>
                <Icons     
                name= "nav-arrow-down"
                width= "1.25vw"
                height= "2.222vh"
                fill= "#374151"
              />
                </div>
              </div>
              {counDrop && <Dropdown dropList={counList} dropFunc={coundropFunc} dropVal={counDropVal} dropClass={"drop6"}/>}
            </div> */}
            {/* <div className={`offer-inputbox input-disable`}>
            <input
            type="text"
            className='offer-input'
            placeholder="Select Country"
            value={userDetails.country}
            disabled
            />
            </div> */}
            <div className='det-text'>User Permission</div>
            <div className={`offer-inputbox ${permDrop && "visited"}`} ref={permRef}>
              <div className='permdrop' onClick={perDropdown}>
                <div style={{color:"#111827"}}>{permDropVal}</div>
                <div className={`droparrr ${permDrop && "arr-rot"}`}>
                <Icons     
                name= "nav-arrow-down"
                width= "1.25vw"
                height= "2.222vh"
                fill= "#374151"
              />
                </div>
              </div>
              <Dropdown drop={permDrop} dropList={permList} dropFunc={permdropFunc} dropVal={permDropVal} dropClass={"drop6"}/>
            </div>
            <div className='det-text'>Status</div>
            <div className='activity'>
              <div style={{color:"#111827"}}>User Activity</div>
              <div className='scheck'>
                <span className='scheck'>
                  {sCheck? "Active":"Inactive"}
                </span>
              <label class="switch">
                <input type="checkbox" checked={sCheck}/>
                <span class="slider round" onClick={toggleSwitch}></span>
              </label>
              </div>
            </div>
        </div>
        <div className={`save-btn ${!save && "save-disable"}`} onClick={handleSave}>Save</div>
      </div>
    </div>
  );
}

export default Sidebar;
