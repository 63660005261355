import React from 'react'

import {ReactComponent as EyeOff} from '../../assets/svg/eye-off.svg'
import {ReactComponent as EyeEmpty} from '../../assets/svg/eye-empty.svg'
import {ReactComponent as ArrowUp} from '../../assets/svg/nav-arrow-up.svg'
import {ReactComponent as ArrowDown} from '../../assets/svg/nav-arrow-down.svg'
import {ReactComponent as NavArrowLeft} from '../../assets/svg/nav-arrow-left.svg'
import {ReactComponent as NavArrowDown} from '../../assets/svg/arrow-down.svg'
import {ReactComponent as Warning} from '../../assets/svg/warning.svg'
import {ReactComponent as MayaLogo} from '../../assets/svg/maya-logo.svg'
import {ReactComponent as Bell} from '../../assets/svg/bell.svg'
import {ReactComponent as Avatar} from '../../assets/svg/avatar.svg'
import {ReactComponent as SingleUpload} from '../../assets/svg/single-upload.svg'
import {ReactComponent as BulkUpload} from '../../assets/svg/bulk-upload.svg'
import {ReactComponent as ActArrow} from '../../assets/svg/act-arrow.svg'
import {ReactComponent as CloudUpload} from '../../assets/svg/cloud-upload.svg'
import {ReactComponent as OfferImg} from '../../assets/svg/pngimg.svg'
import {ReactComponent as Xcircle} from '../../assets/svg/x-circle.svg'
import {ReactComponent as Calendar} from '../../assets/svg/calendar.svg'
import {ReactComponent as CalendarArrow} from '../../assets/svg/calendar-arrow.svg'
import {ReactComponent as BredArrow} from '../../assets/svg/bred-arrow.svg'
import {ReactComponent as Heart} from '../../assets/svg/heart.svg'
import {ReactComponent as Share} from '../../assets/svg/share.svg'
import {ReactComponent as More} from '../../assets/svg/more.svg'
import {ReactComponent as TableMore} from '../../assets/svg/table-more.svg'
import {ReactComponent as CsvIcon} from '../../assets/svg/csv-icon.svg'
import {ReactComponent as PauseCircle} from '../../assets/svg/pause-circle.svg'
import {ReactComponent as Dcircle} from '../../assets/svg/d-circle.svg'
import {ReactComponent as ArrowLeft} from '../../assets/svg/arrow-left.svg'
import {ReactComponent as Cancel} from '../../assets/svg/cancel.svg'
import {ReactComponent as Check} from '../../assets/svg/check.svg'
import {ReactComponent as Filter} from '../../assets/svg/filter.svg'
import {ReactComponent as Search} from '../../assets/svg/search.svg'
import {ReactComponent as Sort} from '../../assets/svg/sort.svg'
import {ReactComponent as LeftArrow} from '../../assets/svg/left-arrow.svg'
import {ReactComponent as RightArrow} from '../../assets/svg/right-arrow.svg'
import {ReactComponent as XCancel} from '../../assets/svg/x-cancel.svg'


const Icons = (props) => {
    const { name, fill, width, height } = props;

return(
    <>
    {name === "eye-off" ? <EyeOff fill={fill} width={width} height={height} />:
    name === "eye-empty" ? <EyeEmpty fill={fill} width={width} height={height} /> :
    name === "arrow-down" ? <ArrowDown fill={fill} width={width} height={height} />:
    name === "arrow-up" ? <ArrowUp fill={fill} width={width} height={height} />:
    name === "nav-arrow-left" ? <NavArrowLeft fill={fill} width={width} height={height} />:
    name === "warning" ? <Warning fill={fill} width={width} height={height} />:
    name === "maya-logo" ? <MayaLogo fill={fill} width={width} height={height} /> :
    name === "bell" ? <Bell fill={fill} width={width} height={height} />:
    name === "avatar" ? <Avatar fill={fill} width={width} height={height} />:
    name === "single-upload" ? <SingleUpload fill={fill} width={width} height={height} />:
    name === "bulk-upload" ? <BulkUpload fill={fill} width={width} height={height} />:
    name === "act-arrow" ? <ActArrow fill={fill} width={width} height={height} />:
    name === "cloud-upload" ? <CloudUpload fill={fill} width={width} height={height} />:
    name === "pngimg" ? <OfferImg fill={fill} width={width} height={height} />:
    name === "x-circle" ? <Xcircle fill={fill} width={width} height={height} />:
    name === "calendar" ? <Calendar fill={fill} width={width} height={height} />:
    name === "calendar-arrow" ? <CalendarArrow fill={fill} width={width} height={height} />:
    name === "bred-arrow" ? <BredArrow fill={fill} width={width} height={height} />:
    name === "heart" ? <Heart fill={fill} width={width} height={height} />:
    name === "share" ? <Share fill={fill} width={width} height={height} />:
    name === "more" ? <More fill={fill} width={width} height={height} />:
    name === "table-more" ? <TableMore fill={fill} width={width} height={height} />:
    name === "csv-icon" ? <CsvIcon fill={fill} width={width} height={height} />:
    name === "pause-circle" ? <PauseCircle fill={fill} width={width} height={height} />:
    name === "d-circle" ? <Dcircle fill={fill} width={width} height={height} />:
    name === "arrow-left" ? <ArrowLeft fill={fill} width={width} height={height} />:
    name === "cancel" ? <Cancel fill={fill} width={width} height={height} />:
    name === "check" ? <Check fill={fill} width={width} height={height} />:
    name === "filter" ? <Filter fill={fill} width={width} height={height} />:
    name === "search" ? <Search fill={fill} width={width} height={height} />:
    name === "sort"? <Sort fill={fill} width={width} height={height} />:
    name === "right-arrow"? <RightArrow fill={fill} width={width} height={height} />:
    name === "left-arrow"? <LeftArrow fill={fill} width={width} height={height} />:
    name === "xcancel" ? <XCancel fill={fill} width={width} height={height} />:
    name === "nav-arrow-down" ? <NavArrowDown fill={fill} width={width} height={height} />:
    null}
    </>
)
   
    
}

export default Icons